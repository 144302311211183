import { render, staticRenderFns } from "./ProductCardSku.vue?vue&type=template&id=7474651e&scoped=true&"
import script from "./ProductCardSku.vue?vue&type=script&lang=js&"
export * from "./ProductCardSku.vue?vue&type=script&lang=js&"
import style0 from "./ProductCardSku.vue?vue&type=style&index=0&id=7474651e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7474651e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default})
