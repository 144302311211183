
export default {
    name: 'ProductBrand',
    props: {},
    data() {
        return {
            collapse: {
                active: [1, 2, 5, 6],
                visible: true
            },
            policy: {
                list: []
            },
            company: {
                list: []
            },
            brand: {
                list: []
            },
            media: {
                list: []
            },
            subscribe: { // 订阅
                hasSubscribe: false,
                email: '',
                tips: '',
                hasCheck: false, // 订阅是否家校验通过
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 邮件订阅
        subscribeAjax() { // 订阅接口
            this.$api.help.setSubscribe({
                data: JSON.stringify({email: this.subscribe.email})
            }).then(res => {
                this.subscribe.hasSubscribe = true
                this.$Toast.success(this.translate('This email has already subsribed'))
            })
        },
        handleSubscribe() { // 订阅
            this.checkSubscribe()
            if(!this.subscribe.hasCheck) {
                return
            }
            this.subscribeAjax()
        },
        inputEmail() { // 订阅输入
            this.checkSubscribe()
        },
        checkSubscribe() { // 订阅校验
            let pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            this.subscribe.tips = ''
            if(!this.subscribe.email) {
                this.subscribe.tips = this.translate('Please enter your email address')
            }
            if(!pattern.test(this.subscribe.email)) {
                this.subscribe.tips = this.translate('Please enter a valid email address')
            }
            this.subscribe.hasCheck = !this.subscribe.tips
        },
        // 折叠面板
        changeCollapse(e) {
            this.collapse.visible = e
        },
        // 跳转
        goLink(obj) {
            if(obj.url) {
                window.location.href = obj.url
                return
            }
            this.$router.push({
                name: obj.name
            })
        },
        jumpMedia(obj) {
            window.location.href = obj.url
        },
        // 翻译
        translate(val) {
            return this.$translate(val)
        },
        // 初始化
        initialSet() {
            this.policy.list = [{
                text: this.translate('Shipping Info'),
                name: 'Policy-Shipping'
            }, {
                text: this.translate('Terms&Conditions'),
                name: 'Policy-Terms'
            }, {
                text: this.translate('Payment Method'),
                name: 'Policy-Pay'
            }, {
                text: this.translate('Privacy Policy'),
                name: 'Policy-Privacy'
            }, {
                text: this.translate('Refund Policy'),
                name: 'Policy-Return'
            }]
            this.company.list = [{
                text: this.translate('About Us'),
                name: 'Policy-About-Us'
            }, {
                text: this.translate('Contact Us'),
                name: 'Policy-Contact-Us'
            }, {
                text: this.translate('YFN Blog'),
                url: '/blog/'
            }]
            this.brand.list = [{
                class: 'new-c-brand--9'
            }, {
                class: 'new-c-brand--12'
            }, {
                class: 'new-c-brand--8'
            }, {
                class: 'new-c-brand--7'
            }, {
                class: 'new-c-brand--6'
            }, {
                class: 'new-c-brand--3'
            }, {
                class: 'new-c-brand--2'
            }, {
                class: 'new-c-brand--13'
            }, {
                class: 'new-c-brand--11'
            }, {
                class: 'new-c-brand--1'
            }, {
                class: 'new-c-brand--5'
            }, {
                class: 'new-c-brand--10'
            }, {
                class: 'new-c-brand--4'
            }]
            this.media.list = [{
                class: 'new-c-media-1',
                url: 'https://www.facebook.com/YFNCOM'
            }, {
                class: 'new-c-media-5',
                url: 'https://www.instagram.com/yfnjewelry_official'
            }, {
                class: 'new-c-media-2',
                url: 'https://www.pinterest.com/YFNCOM'
            }, {
                class: 'new-c-media-4',
                url: 'https://www.tiktok.com/@yfn_uk_official'
            }, {
                class: 'new-c-media-3',
                url: 'https://www.youtube.com/channel/UCikbSP-wQtjFQJ1U2yzrDbQ'
            }]
        }
    },
}
