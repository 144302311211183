
import ProductCardSku from './ProductCardSku.vue'

export default {
    name: 'ProductCard',
    components: {
        ProductCardSku
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        info: Object,
        addSuccess: Object,
        middleEmit: Object
    },
    data() {
        return {
            clear: '',
            card: {
                visible: false,
                title: '',
                list: [],
            },
            showCheck:[true,true],
            select: [{
                previewImg: '',
                hasSelectProduct: false,
                hasSelectSku: false,
                hasCardSku: false,
                product: {},
                sku: {},
                all: {}
            },{
                previewImg: '',
                hasSelectProduct: false,
                hasSelectSku: false,
                hasCardSku: false,
                product: {},
                sku: {},
                all: {}
            }],
            params: {
                add: {}
            },
            cardList:[]
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.info.spuId && this.info.goodsType != 7 && this.getCardAjax()
            },
            deep: true,
            immediate: true,
        },
        addSuccess: {
            handler(n, o) {
                this.setAdd()
            },
            deep: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        showProd(index,item){
            if(item.showStyle){
                this.showCheck[index] = !this.showCheck[index]
                this.$forceUpdate()
            }
        },
        changeCheck(index){
            if(!this.showCheck[index]){
                this.select[index]={
                    previewImg: '',
                    hasSelectProduct: false,
                    hasSelectSku: false,
                    hasCardSku: false,
                    product: {},
                    sku: {},
                    all: {}
                }
            }
        },
        // 获取贺卡商品
        getCardAjax() {
            this.$api.product.getCardProduct({
                scene: 'detail',
                spuId: this.info.spuId
            }).then(response => {
                const res = response.result || [];
                this.cardList = res
                this.cardList.forEach((item,index)=>{
                    if(item.showStyle){
                        this.showCheck[index] = false
                    }
                })
                // this.card.title = res[0].title
                // this.card.list = res[0].items || []
                // this.card.visible = !!this.card.list.length
                this.setCardData()
            })
        },
        setCardData() {
            this.cardList.map(res=>{
                res.items.forEach(p => {
                    // 图片
                    const firstPics = p.pics[0] || {};
                    p.previewImg = firstPics.url
                    p.displayMarketPrice = p.displaySalePrice
                    if(p.skus.length < 2) {
                        const firstSku = p.skus[0] || {};
                        p.previewImg = firstSku.skuPic
                        p.displayMarketPrice = firstSku.displayMarketPrice
                    }
                })
            })
        },
        // 加购
        setAddAjax() { // 加购接口
            this.setBuried()
            this.$api.product.setAddBag({
                data: JSON.stringify(this.params.add)
            }).then(response => {
                this.$updateBagQuantity()
            })
        },
        setBuried() {
            try {
                this.select.forEach((item,index)=>{
                    const select = item.sku;
                    if(select.id){
                        this.$fnApi.buried.tiktok({
                            type: 'AddToCart',
                            params: {
                                content_type: 'product',
                                quantity: 1,
                                content_id: select.spuCode,
                                currency: 'USD',
                                price: this.setSymbolReplace((select.salePrice/100).toFixed(2)),
                                value: this.setSymbolReplace((select.salePrice/100).toFixed(2))
                            }
                        })
                    }
                })
            } catch (error) {}
        },
        setAdd() {
            this.params.add = [];
            let hasCardSku = false
            this.select.forEach((item,index)=>{
                const select = item.sku;
                if(select.id){
                    this.params.add.push({
                        skuId: select.id,
                        spuId: select.spuId,
                        quantity: 1,
                        customizeType: 1,
                        isVipService: false
                    })
                }
                if(item.hasCardSku){
                    hasCardSku = true
                }
            })
            this.addSuccess?.main_cart_id && hasCardSku && this.setAddAjax()
        },
        getSelect(){
            let add = [];
            this.select.forEach((item,index)=>{
                const select = item.sku;
                if(select.id){
                    let range = '';
                    select.specs?.map(res=>{
                        range += `${res.specName||''}:${res.specVal||''}`
                    })
                    add.push({
                        displaySalePrice: select.displaySalePrice,
                        productName: select.productName,
                        range: range,
                        skuPic:select.skuPic
                    })
                }
            })
            return add
        },
        // 预览图
        handleImg(obj) {
            if(!obj.hasSelect) {
                this.$ImagePreview({
                    images: [obj.previewImg],
                })
                return
            }
            const picsArr = this.select[obj.index].all.sku.mergeList || [];
            let pics = [];
            picsArr.forEach(p => {
                pics.push(p.skuPic)
            })
            const i = pics.indexOf(this.select[obj.index].previewImg);
            const index = i != -1 ? i : 0;
            this.$ImagePreview({
                images: pics,
                startPosition: index
            })
        },
        // 选择
        handleChoose(obj,index) {
            this.select[index].hasSelectSku = false
            this.select[index].hasCardSku = false
            if(this.select[index].hasSelectProduct && this.select[index].product.productCode == obj.productCode) {
                this.select[index].product = {}
                this.select[index].sku = {}
                this.select[index].all = {}
                this.select[index].hasSelectProduct = false
            } else {
                this.select[index].hasSelectProduct = true
                this.select[index].product = obj
            }
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.select[index].hasCardSku = this.select[index].hasSelectProduct
            }, 50)
            this.$forceUpdate()
        },
        // sku change
        changeSkuSelect(obj) {
            this.select[obj.index].sku = obj.select || {}
            this.select[obj.index].previewImg = this.select[obj.index].sku.skuPic
            this.select[obj.index].hasSelectSku = this.select[obj.index].sku.hasAllSelect
            this.select[obj.index].all = obj || {}
            this.middleEmit.fn('cardSelectEmit', {
                ...this.select[obj.index]
            })
        },
        // 跳转
        jumpProduct(obj) {
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: obj.productCode,
                    seoUrl: obj.seoUrl
                }
            })
        }
    },
}
