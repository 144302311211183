
export default {
    name: 'ProductBanner',
    props: {
        info: Object,
        skuSelect: Object
    },
    data() {
        return {
            rsInfo: {},
            list: [], // list 数据
            swiperCompo: { // YfnSwiper 组件配置项
                options: {}
            },
            preview: { // 预览图
                visible: false,
                url: '',
                slideIndex: 0,
                src: []
            },
            preVideo: { // 视频播放
                hasPlay: false,
                ref: ''
            },
            ossImage(img,resize){
                return img.includes('https://')||img.includes('http://') ? img + resize : img
            }
        }
    },
    watch: {
        info: {
            handler(n, o) {
                this.setInfo()
            },
            deep: true,
            immediate: true
        },
        skuSelect: {
            handler(n, o) {
                // this.preview.visible = n.hasAllSelect && !n.hasOnlySku
                // this.preview.visible && (this.preview.url = n.skuPic)
                this.setSkuSelect()
            },
            deep: true
        }
    },
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 设置数据
        setInfo() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
            this.setListData()
        },
        // 选择后数据变更
        setSkuSelect() {
            this.setListData()
        },
        swiperLoad(){
            this.$refs?.YfnSwiper?.swiper1?.slideTo(1, 100, false)
        },
        // 设置banner
        setListData(img) {
            const skuSelect = this.skuSelect || {}
            const mainVideos = skuSelect.hasAllSelect ? skuSelect.mainVideos :  this.rsInfo?.mainVideos || [];
            const pics = skuSelect.hasAllSelect ? skuSelect.skuPics :  this.rsInfo.pics || [];
            const length = pics.length;
            this.list = img?[img,...pics]:[...pics]
            this.$refs?.YfnSwiper?.swiper1?.slideTo(1, 100, false)
            if(length && mainVideos) {
                this.list?.splice(length, 0, ...mainVideos)
                // this.list.splice(length - 1, 0, ...mainVideos)
            }
            this.preview.src = [];
            this.list.forEach((item, i) => {
                const url = item.url || ''
                item.hasVideo = url.includes('.mp4')
                if(!item.hasVideo) {
                    // 预览图
                    this.preview.src.push(item.url)
                } else {
                    // 视频
                    item.ref = `video${i + 1}`
                    item.showVideo = true
                    item.controls = false
                }
            })
        },
        // swiper
        slideChange() {
            this.preview.visible = false
            // 暂停video播放
            this.videoPause()
        },
        // 预览
        handlePreview(obj) {
            this.preview.slideIndex = obj.$index
            this.$ImagePreview({
                images: this.preview.src,
                startPosition: this.preview.slideIndex,
                getContainer: () => {
                    return document.querySelector('.product')
                },
                onClose: (e) => {
                    this.$refs.YfnSwiper.swiper1.slideTo(e.index + 1, 100, false)
                }
            })
        },
        // 视频播放
        handleVideo(obj) {
            // 视频播放
            this.preVideo.ref = ''
            if(obj.ref) {
                this.preVideo.ref = this.$refs[obj.ref]
                this.preVideo.ref.play()
                this.preVideo.hasPlay = true
            }
        },
        videoPause() { // 视频播放-暂停
            this.preVideo.hasPlay = false
            if(this.preVideo.ref) {
                this.preVideo.ref.pause()
            }
        },
        // 初始化设置
        initialSet() {
            this.swiperCompo.options ={
                loop: true,
                initialSlide: 0,
                pagination: {
                    type: 'fraction'
                },
                on: {
                    slideChange: (e) => this.slideChange()
                }
            }
        }
    },
}
