

export default {
    name: 'ProductDesc',
    props: {
        info: Object,
        skuSelect: Object,
    },
    data() {
        return {
            collapse: {
                active: [1],
                visible: true
            },
            langText: {
                title1: '',
                title2: ''
            },
            list: [],
            detailModuleData:[]
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
                // this.setLinkClear() //隐藏链接去除a标签
            },
            deep: true,
            immediate: true
        },
        skuSelect: {
            handler(n, o) {
                // this.preview.visible = n.hasAllSelect && !n.hasOnlySku
                // this.preview.visible && (this.preview.url = n.skuPic)
                this.setData()
            },
            deep: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 设置数据
        setData() {
            const props = this.skuSelect?.props || this.info?.props || []
            let batCate = this.info?.backCat?.pullPathEn.split('/') || []
            this.list = [{
                propName: this.$translate('Product ID'),
                propValue: this.info.productCode
            },{
                propName: this.$translate('Brand'),
                propValue: 'YFN'
            },{
                propName: this.$translate('Category'),
                propValue: batCate?.pop()
            }]
            props.forEach(item => {
                this.list.push({
                    propName: item.propName,
                    propValue: item.propValue.join(', ') == this.$translate('Unisex')?this.$translate('Unisex(Women,Men)'):item.propValue.join(', ')
                })
            })

            this.detailModuleData = this.info?.detailModuleData || []
        },
        // 描述链接去除
        setLinkClear() {
            if(process.client) {
                this.$nextTick(() => {
                    let tag = document.querySelectorAll('.ProductDesc-desc a') || []
                    tag.forEach(e => {
                        e.style.display = 'none'
                        e.after(e.innerText)
                    })
                })
            }
        },
        // 折叠面板
        changeCollapse(e) {
            this.collapse.visible = e
        },
        // 复制
        handleCopy() {
            this.$fnApi.copy.initial(this.info.productCode)
            this.$Toast(this.$translate('Copied'))
        },
        // 初始化
        initialSet() {
            this.langText.title1 = this.$translate('Overview')
            this.langText.title2 = this.$translate('Detail')
        }
    },
}
