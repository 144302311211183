

export default {
    name: 'ProductBundleNew',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        info: Object,
        addSuccess: Object,
        middleEmit: Object,
        skuSelect: Object
    },
    data() {
        return {
            clear: '',
            select: {
                visible: false,
                product: {},
                productCode:'',
                options: {
                    hasCusJump: false,
                    hasImdAddBag: false,
                    btnText:this.$translate('CHOOSE THIS ONE'),
                    hasDetailInfo:true,
                    hasQuantity:false
                }
            },
            params: {
                add: {}
            },
            newBindGroupGoods:[],
            clear: '',
            bundleInfo:{}
        }
    },
    computed: {},
    watch: {
        addSuccess: {
            handler(n, o) {
                this.setAdd()
            },
            deep: true
        },
        skuSelect: {
            handler(n, o) {
                this.bundleChange()
            },
            deep: true
        },
    },
    created() {
        this.getList()
    },
    mounted() {},
    methods: {
        emitSuccess(obj){
            this.newBindGroupGoods.map(item=>{
                if(item.productCode==obj.sku.select.spuCode){
                    item.hasSelectProduct = true
                    item.skuId = obj.sku.select.id
                    item.displayOrgPrice = obj.sku.select.displayOrgPrice
                    item.displaySalePrice = obj.sku.select.displaySalePrice
                    item.skuPic = obj.sku.select.skuPic,
                    item.specs = obj.sku.select.specs
                }
            })
            this.bundleChange()
            setTimeout(() => {
                this.select.options.visible = false
                this.select.visible = false
            }, 50)
        },
        bundleChange(){
            let isBundle = this.newBindGroupGoods.find(res=>{return res.hasSelectProduct})
            if(!isBundle) {
                this.middleEmit.fn('bundlePrice', {
                    useCurrencyOrgPrice:this.info.useCurrencyOrgPrice,
                    useCurrencySalePrice:this.info.useCurrencySalePrice,
                    displaySalePrice:this.info.displaySalePrice,
                    displayOrgPrice:this.info.orgPrice > this.info.salePrice ? this.info.displayOrgPrice : 0,
                })
                this.bundleInfo.displayBundleDiscountPrice = 0
                return
            }
            let spuItems = [{
                spuId:this.skuSelect.spuId,
                skuId:this.skuSelect.id,
                thisGoods:true
            }]
            this.newBindGroupGoods.map(item=>{
                if(item.hasSelectProduct){
                    spuItems.push({
                        spuId:item.id,
                        skuId:item.skuId,
                        thisGoods:false
                    })
                }
            })
            this.$api.product.setBundleCalc({
                data: JSON.stringify({
                    activityId: this.info.activityInfo.activityId,
                    spuItems: spuItems
                })
            }).then(res=>{
                this.bundleInfo = res.result
                this.bundleInfo.bundleAssists.map(list=>{
                    this.newBindGroupGoods.map(list2=>{
                        if(list.skuId==list2.skuId){
                            list2.displayOrgPrice = list.displayOrgPrice
                            list2.displaySalePrice = list.displaySalePrice
                        }
                    })
                })
                this.middleEmit.fn('bundlePrice', this.bundleInfo.bundleAssists[0])
            })
        },
        emitClose(){
            setTimeout(() => {
                this.select.visible = false
            }, 50)
        },
        getList(){
            let list = this.info?.activityInfo?.newBindGroupGoods
            list.map(res=>{
                res.hasSelectProduct = false
            })
            this.newBindGroupGoods = JSON.parse(JSON.stringify(list))
        },
        // 加购
        setAddAjax(params) { // 加购接口
            this.setBuried()
            this.$api.product.setAddBag({
                data: JSON.stringify(params)
            }).then(response => {
                this.$updateBagQuantity()
            })
        },
        setBuried() {
            try {
                this.newBindGroupGoods.map(res=>{
                    if(res.hasSelectProduct){
                        this.$fnApi.buried.tiktok({
                            type: 'AddToCart',
                            params: {
                                content_type: 'product',
                                quantity: 1,
                                content_id: res.productCode,
                                currency: 'USD',
                                price: this.setSymbolReplace((res.salePrice/100).toFixed(2)),
                                value: this.setSymbolReplace((res.salePrice/100).toFixed(2))
                            }
                        })
                    }
                })
            } catch (error) {}
        },
        setAdd() {
            let params = [];
            let isBundle = this.newBindGroupGoods.find(res=>{return res.hasSelectProduct})
            if(!isBundle) return
            this.newBindGroupGoods.map(res=>{
                if(res.hasSelectProduct){
                    params.push({
                        skuId: res.skuId,
                        spuId: res.id,
                        quantity: 1,
                        customizeType: 1,
                        isVipService: false
                    })
                }
            })
            this.addSuccess?.main_cart_id && this.setAddAjax(params)
        },
        getSelect(){
            let add = [];
            this.newBindGroupGoods.map(res=>{
                if(res.hasSelectProduct){
                    let range = '';
                    res.specs?.map(res=>{
                        range += `${res.specName||''}:${res.specVal||''}`
                    })
                    add.push({
                        displaySalePrice: res.displaySalePrice,
                        displayOrgPrice: res.displayOrgPrice,
                        productName: res.productName,
                        range: range,
                        skuPic:res.skuPic
                    })
                }
            })
            return add
        },
        // 选择
        handleChoose(obj) {
            if(obj.hasSelectProduct) {
                let list = this.info?.activityInfo?.newBindGroupGoods
                this.newBindGroupGoods[obj.$index].hasSelectProduct = false
                this.newBindGroupGoods[obj.$index].displayOrgPrice = list[obj.$index].displayOrgPrice
                this.newBindGroupGoods[obj.$index].displaySalePrice = list[obj.$index].displaySalePrice
                this.bundleChange()
            } else {
                if(obj.skus.length>1){
                    this.select.product = obj
                    this.select.productCode = obj.productCode
                    this.clear && clearTimeout(this.clear)
                    this.clear = setTimeout(() => {
                        this.select.options.visible = true
                        this.select.visible = true
                    }, 50)
                }else{
                    let skuObj = obj.skus[0]
                    this.emitSuccess({
                        sku:{
                            select:skuObj
                        }
                    })
                }
            }
            this.$forceUpdate()
        },
        openDia(obj){
            this.select.product = obj
            this.select.productCode = obj.productCode
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.select.options.visible = true
                this.select.visible = true
            }, 50)
        },
        // sku change
        changeSkuSelect(obj) {
            this.select.sku = obj.select || {}
            this.select.previewImg = this.select.sku.skuPic
            this.select.all = obj || {}
            this.middleEmit.fn('cardSelectEmit', {
                ...this.select
            })
        },
        // 跳转
        openProduct(obj) {
            console.log(obj)
        }
    },
}
