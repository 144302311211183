
export default {
    name: 'ProductBundle',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        middleEmit: Object,
        info: Object
    },
    data() {
        return {
            showMore:false,
            clear: '',        // 节流
            rsInfo: {         // 商品信息
                rs: {}
            },
            swiper: '',
            visible: false,
            list: [],         // 捆绑组合
            target: {         // 当前组合
                index: 0,     // 捆绑组合项下标
                cIndex: 0,    // 组合子项下标
                type: 1,      // 组合子项设置类型 1 sku变更 2 定制
                info: {},     // 组合项信息
                cInfo: {},    // 组合子项信息
                detail: {},   // 组合子项商品信息
                list: [],     // 组合数据
                skuDown: {},  // 组合子项切换sku结果
            },
            select: {},       // 主商品sku选择信息
            popup: {          // 组合弹窗
                visible: false,
            },
            bundleSku: {      // 捆绑sku切换
                info: {},
                options: {
                    visible: false,
                    hasImdAddBag: false
                }
            },
            customizeCompo: { // 定制页
                visible: false,
                hasPopup: true,
                url: '',
                on: {}
            },
            params: {         // 参数
                hasCheck: false, // 是否校验通过
                add: [],      // 组合加购参数
                calc: [],     // 组合价格计算参数
            },
            bagCompo: {            // 加购组件参数
                visible: false,
                product: {},
                options: {
                    visible: false,
                    hasCusJump: false,
                    hasImdAddBag: true
                },
                sku: {
                    showOnlySku: true,
                    skuMId: ''
                }
            },
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        handleBag(obj) { // 加购
            const selectSku = obj.selectSku || {}
            this.bagCompo.options.visible = false
            this.bagCompo.visible = false
            this.bagCompo.product = obj
            this.bagCompo.sku.skuMId = selectSku.id
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.bagCompo.options.visible = true
                this.bagCompo.visible = true
            }, 50)
        },
        emitSuccess(obj) { // 加购回调
            this.$updateBagQuantity()
            // this.$emit('emit-add', obj)
        },
        emitClose() {
            let clear = setTimeout(() => {
                this.bagCompo.visible = false
                clearTimeout(clear)
            }, 200)
        },
        popupAssists:(e)=>{
            let list = JSON.parse(JSON.stringify(e))
            list.splice(1, 0, {hasSpace: true})
            list.forEach(item => {
                item.hasSelect = false
                if(item.customizeType == 2 && (!item.customizeInfo || (item.customizeInfo && !item.customizeInfo.length))) {
                    item.hasSelect = true
                    item.simpleSpec = [{
                        specName: 'Select',
                        specVal: 'Select'
                    }]
                }
            })
            return list
        },
        // 数据处理
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
            this.rsInfo.activityInfo = this.rsInfo.isBindSpu ? this.rsInfo.bindActivityInfo : {}
            if(!this.rsInfo.activityInfo.bundleGroupVOS) {
                this.rsInfo.activityInfo.bundleGroupVOS = []
            }
            let list = this.rsInfo.activityInfo.bundleGroupVOS || [];
            this.rsInfo.rs = {}
            this.rsInfo.rs.length = list.length
            this.rsInfo.rs.cMLength = 0
            this.rsInfo.rs.visible = list.length
            this.list = list
            this.setList()
        },
        setList() { // 捆绑列表
            let list = this.list;
            list.forEach(item => {
                const cLength = item.bundleAssists.length
                let rsLength = this.rsInfo.rs.cMLength;
                this.rsInfo.rs.cMLength = cLength > rsLength ? cLength : rsLength
            })
            this.target.index = 0
            this.setTargetData()
            this.setStyle()
        },
        setTargetData() { // 当前组合数据
            if(!this.rsInfo.rs.visible) {
                return
            }
            let list = JSON.parse(JSON.stringify(this.list))
            this.target.info = list[this.target.index]
            this.target.list = list[this.target.index].bundleAssists
            this.target.list.splice(1, 0, {hasSpace: true})
            this.target.list.forEach(item => {
                item.hasSelect = false
                if(item.customizeType == 2 && (!item.customizeInfo || (item.customizeInfo && !item.customizeInfo.length))) {
                    item.hasSelect = true
                    item.simpleSpec = [{
                        specName: 'Select',
                        specVal: 'Select'
                    }]
                }
            })
            this.setTargetImg()
            this.$emit('emit-bundle-select', {
                select: this.target,
                info: this.rsInfo
            })
        },
        setTargetImg() { // 组合子项图片
            let list = this.target.list;
            list.forEach(item => {
                item.rsPic = item.skuPic
                if(item.customizeInfo && item.customizeInfo.length) {
                    let rsPic = item.customizeInfo[0].url || '';
                    rsPic && (item.rsPic = rsPic)
                }
            })
        },
        setStyle() { // 组合样式
            const cMLength = this.rsInfo.rs.cMLength
            let length = 1
            if(cMLength > 3) {
                length = 3
            }else if(cMLength > 2) {
                length = 2
            }
            this.rsInfo.rs.class = `ProductBundle-item${length}`
        },
        // 切换组合
        handleCut(val) {
            let hasCut = false
            this.$fnApi.debounce(() => {
                if(val == 1) {
                    if(this.target.index) {
                        hasCut = true
                        this.target.index -= 1
                    }
                } else {
                    if(this.target.index < this.rsInfo.rs.length - 1) {
                        hasCut = true
                        this.target.index += 1
                    }
                }
                hasCut && this.setTargetData()
            }, 100)
        },
        addBagSku(val){
            this.target.index = val
            this.setTargetData()
            this.handleDesc()
            // this.handleSubmit()
        },
        // 显示当前组合弹窗
        handleDesc() {
            this.popup.visible = true
        },
        // 设置参数
        setParams() {
            let add = [], calc = [];
            this.params.hasCheck = true
            this.target.list.forEach((item, i) => {
                // 加购参数
                if(!item.hasSpace) {
                    add.push({
                        skuId: item.skuId,
                        spuId: item.id,
                        quantity: item.quantity || 1,
                        customizeType: item.customizeType || 1,
                        customizeInfo: item.customizeInfo || [],
                        customizeId: item.customizeId || ''
                    })
                    calc.push({
                        skuId: item.skuId,
                        spuId: item.id,
                        thisGoods: !i,
                        customizeInfo: item.customizeInfo || [],
                        customizeId: item.customizeId || '',
                    })
                    if(item.customizeType == 2 && !item.customizeInfo || (item.customizeInfo && !item.customizeInfo.length)) {
                        this.params.hasCheck = false
                    }
                }
            })
            this.params.add = add
            this.params.calc = calc
        },
        // 加购
        setAddAjax() { // 加购接口
            this.setAddLogAjax() // 加购日志
            this.$api.product.setAddBag({
                data: JSON.stringify(this.params.add)
            }).then(response => {
                this.popup.visible = false
                this.showMore = false
                this.$Toast(this.$translate('Added to Bag successfully!'))
                this.$updateBagQuantity()
            })
        },
        // 加购日志
        setAddLogAjax() { // 加购结算日志接口
            let log = {
                addPage: 2,
                traceType: 1,
                spuItems: this.params.add,
                settleSource: this.info.id,
            };
            try {
                this.setBuried()
            } catch (error) {
                console.log(error)
            }
            this.$api.product.setAddBagLog({
                ...log
            }).then(response => {})
        },
        // 加购埋点
        setSymbolReplace(val) {
            try {
                return val && val.includes(',') ? val.replaceAll('.', '').replaceAll(',', '.').replace(/[^\d.-]/g, '') : val
            } catch {
                return val
            }
        },
        setBuried() {
            let addItems = []
            let Ids = []
            // // gtag 加购事件埋点
            this.$fnApi.buried.gtag({
                type: 'conversion_cart',
                params: {
                    'value': this.setSymbolReplace((this.target?.info?.bundlePrice/100).toFixed(2)),
                    'currency': 'USD'
                }
            })
            this.target?.list?.map(res=>{
                if(res.id){
                    addItems.push({
                        item_id: res.id,
                        group_item_id: res.spuCode,
                        item_name: res.productName,
                        quantity: 1,
                        price: this.setSymbolReplace((res.salePrice/100).toFixed(2)),
                        affiliation: 'YFN',
                        item_brand: 'YFN',
                        index: 0,
                        item_variant: res.sellerSku,
                    })
                    Ids.push(res.productCode)
                }
            })
            this.$fnApi.buried.gtag({
                type: 'add_to_cart',
                params: {
                    currency: 'USD',
                    value: this.setSymbolReplace((this.target?.info?.bundlePrice/100).toFixed(2)),
                    items: addItems
                }
            })
            this.$fnApi.buried.fbq({
                type: 'AddToCart',
                params: {
                    content_type: 'product',
                    content_ids: Ids,
                    currency: 'USD',
                    value: this.setSymbolReplace(this.target?.info?.displayBundlePrice),
                    contents: addItems
                }
            }, this)
        },
        setCheck() {
            if(!this.params.hasCheck) {
                this.$Toast(this.$translate('Please Select Style'))
            }
        },
        handleSubmit(val) { // 加购
            this.setParams()
            this.setCheck()
            this.params.hasCheck && this.setAddAjax()
        },
        // 主商品sku更换捆绑商品重新计算
        getBundleDataAjax() { // 捆绑价格重新计算接口
            this.$api.product.setBundleCalc({
                data: JSON.stringify({
                    activityId: this.rsInfo.activityInfo.activityId,
                    spuItems: this.params.calc
                })
            }).then(response => {
                const res = response.result || {};
                this.customizeCompo.visible = false
                this.setChangeSkuTarget(res)
            })
        },
        setChangeSkuTarget(obj) { // 商品sku改变捆绑商品信息更新
            let i = this.target.cIndex > 0 ? this.target.cIndex - 1 : this.target.cIndex;
            this.target.info = obj || {}
            this.target.list[this.target.cIndex] = obj.bundleAssists[i] || {}
            // this.target.list.splice(1, 0, {hasSpace: true})
            this.target.list.forEach(item => {
                if(item.customizeParams) {
                    item.customizeInfo = item.customizeParams
                    item.customizeId = this.target.skuDown.customizeId || item.customizeId
                }
            })
            this.setTargetImg()
            this.$emit('emit-bundle-select', {
                select: this.target,
                info: this.rsInfo
            })
        },
        changeSkuSelect(obj) { // 商品sku改变重新设置捆绑商品
            if(this.rsInfo.goodsType != 10) {
                return
            }
            this.select = obj || {}

            if(this.select.options.hasOnlySku) {
                return
            }
            let list = this.rsInfo.activityInfo.bundleGroupVOS || [];
            if(this.select.hasAllSelect) {
                let i = 0
                this.$set(this.target.list, i, {...this.target.list[i], skuId: this.select.id,spuId: this.select.spuId,})
                this.setParams()
                this.getBundleDataAjax()
            } else {
                this.list = list
                this.setList()
            }
        },
        // bundle更换sku-------------------------------------------------
        setBundleSkuDone(obj) { // bundle商品sku切换后
            this.target.skuDown = obj || {}
            let list = this.target.list;
            const sku = this.target.skuDown.sku
            const i = this.target.cIndex;
            let skuDown = this.target.skuDown;
            let listObj = {
                skuId: sku.id,
                id: sku.spuId,
                simpleSpec: sku.specs,
                pics: [{url: sku.skuPic}],
                skuPic: sku.skuPic,
                salePrice: sku.marketPrice,
                displaySalePrice: sku.displayMarketPrice,
            }
            if(skuDown.customizeInfo) {
                listObj.customizeInfo = skuDown.customizeInfo
                listObj.customizeId = skuDown.customizeId
            }
            this.$set(list, i, {...list[i], ...listObj})
            this.setTargetImg()
            this.setParams()
            this.getBundleDataAjax()
        },
        handleSku(obj) { // bundle sku点击
            if(!obj.hasSpace) {
                this.target.type = 1
                this.target.cIndex = obj.index
                this.target.cInfo = obj
                this.$fnApi.debounce(() => {
                    if(!obj.specStatus) {
                        this.handleCustomize(obj)
                    } else {
                        this.bundleSku.info = this.target.cInfo
                        this.bundleSku.options.visible = true
                        this.bundleSku.options.btnText = this.$translate('Confirm')
                    }
                }, 200)
            }
        },
        skuConfirm(obj) { // bundle sku弹窗 confirm
            const sku = obj?.sku?.select || {};
            if(!sku.hasAllSelect) return
            this.bundleSku.options.visible = false
            this.setBundleSkuDone(obj)
        },
        handleCustomize(obj) { // bundle 定制点击
            if((!obj.hasSpace && (obj.customizeType == 2 && obj.customizeInfo)) || !obj.specStatus) {
                this.target.type = 2
                this.target.cIndex = obj.index
                this.target.cInfo = obj
                this.setBundleCustomize(obj)
            } else {
                this.jumpProduct(obj)
            }
        },
        setBundleCustomize(obj) { // bundle 定制弹窗显示
            let queryArr = [];
            let queryStr = '';
            let query = {
                spu: obj.id,
                sku: obj.skuId,
                productCode: obj.productCode,
                customtype: 3
            };
            if(obj.customizeId) {
                query.customizeId = obj.customizeId
            }
            Object.keys(query).forEach(key => {
                queryArr.push(`${key}=${query[key]}`)
            })
            queryStr = queryArr.join('&')
            this.customizeCompo.visible = true
            let origin = location.origin
            if(origin.includes('10.10.10')){
                origin = 'http://10.10.10.119:8080'
            }
            this.customizeCompo.url = `${origin}/custonIndex?${queryStr}`
            this.customizeCompo.on.setCustomizeDone = this.setCustomizeDone
        },
        setCustomizeDone(obj) { // bundle 定制页-定制完成
            const data = JSON.parse(obj.data);
            this.setBundleCusDone(data)
        },
        setBundleCusDone(obj) { // bundle 定制后
            let list = this.target.list;
            const i = this.target.cIndex;
            let listObj = {
                customizeInfo: obj.customizeInfo || [],
                customizeId: obj.customizeId || ''
            }
            this.$set(list, i, {...list[i], ...listObj})
            this.setTargetImg()
            this.setParams()
            this.getBundleDataAjax()
        },
        // 定制商品-服务图预览
        handlePreCustomize(obj) {
            this.$ImagePreview({
                images: [obj.inputImage || obj.optionValue]
            })
        },
        // 跳转
        jumpProduct(obj) {
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: obj.productCode,
                    seoUrl: obj.seoUrl
                }
            })
        },
    }
}
